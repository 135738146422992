.textarea {
   width: 100%;
   height: 200px;
   padding: 12px 16px;
   border-radius: 12px;
   background: #F9F9F9;
   border: none;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   resize: none;
}

.textarea:focus {
   outline: none;
}