.fileImage {
   width: 48px;
   height: 48px;
   overflow: hidden;
   border-radius: 12px;
}

.fileImage img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.fileContainer {
   display: flex;
}

.containerUpload,
.fileUpload,
.uploadBox {
   width: 100%;
}

.uploadBtn {
   width: 100%;
   height: 80px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 12px;
   border: 1px dashed #EA464F;
   font-size: 12px;
   font-weight: 400;
   line-height: 16px;
   cursor: pointer;
   color: #EA464F;
   letter-spacing: -0.12px;
}

.dN[type="file"] {
   display: none;
}

.fileInfoBlock {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-left: 24px;
}

.fileContainer:not(:first-child) {
   margin-top: 16px;
}

.deleteBtn {
   color: #B9B9B9;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   cursor: pointer;
}