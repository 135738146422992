.modal{
   height: 100vh;
   width: 100vw;
   background: rgba(0, 0, 0, .4);
   position: fixed;
   top: 0;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   opacity: 0;
   pointer-events: none; /* Делает чтобы клик мыши проходил сквозь объект */
   transition: 0.5s;
   border-radius: 10px;
}
.modal.active{
   opacity: 1;
   pointer-events: all;
}
.modal__content{
   
   padding: 20px;
   background: white;
   transform: scale(.5);
   transition: 0.4s all;
   border-radius: 10px;
   width: 126px;
}
.modal__content.active{
   transform: scale(1);
}