.menuContainer {
   display: flex;
   flex-direction: column;
   padding: 0 16px;
}

.linkMenu {
   position: relative;
   padding: 0 16px;
   font-size: 12px;
   font-weight: 400;
   line-height: 16px;
   line-height: 32px;
   width: 200px;
   height: 32px;
   border-radius: 30px;
   text-decoration: none;
   color: #303030;
   box-sizing: border-box;
}

.linkMenu:not(:first-child) {
   margin-top: 12px;
}

.active {
   background: #EA464F14;
   color: #EA464F;
}


/* .itemArrow:hover:after {
   transform: rotate(180deg);
}

.itemArrow {
   margin-right: 24px;
}

.itemArrow:after {
   content: '';
   position: absolute;
   bottom: calc(50% - 12px);
   left: calc(100% + 4px);
   display: inline-block;
   width: 24px;
   height: 24px;
   transition: all ease-in-out .4s;
   background: url('../../assets/icons/arrow_down.svg') 0 0/auto auto no-repeat;
}

.linkMenu.active {
   color: #EA464F;
   background: #EA464F14;
} */