.container {
   display: flex;
   flex-direction: column;
   gap: 40px;
}

.description {
   font-size: 16px;
   line-height: 24px;
   color: #808080;
}

.titleRow {
   display: flex;
   justify-content: space-between;
}

.checkbox {
   width: 48px;
}

.title {
   width: 698px;
}

.published {
   width: 148px;
}

.date {
   width: 188px;
}